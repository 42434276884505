<template>
  <b-form>
    <b-card>
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="formData.title"
          placeholder="Başlık"
        />
      </b-form-group>
    </b-card>
    <b-card title="Yetkiler">
      <b-list-group>
        <b-list-group-item
          v-for="(ability,key) in abilities"
          :key="key"
        >
          <div class="font-weight-bold text-primary mb-1">
            {{ ability.group }}
          </div>
          <b-row>
            <b-col
              v-for="item in ability.items"
              :key="item.id"
              xs="12"
              md="2"
            >
              <b-form-checkbox
                v-model="formData.user_type_ability"
                :value="item.id"
              >
                {{ item.title }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'UserTypesEdit',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      detailForm: false,
      submitStatus: false,
      formData: {
        title: null,
      },
    }
  },
  computed: {
    userStatusData() {
      return this.$store.getters['userTypes/getUser_type']
    },
    abilities() {
      return this.$store.getters['abilities/getAbilities']
    },
    saveStatus() {
      const status = this.$store.getters['userTypes/getUser_typeSaveStatus']
      return status
    },
  },
  watch: {
    userStatusData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getUserType()
    this.getAbilities()
  },
  methods: {
    getUserType() {
      this.$store.dispatch('userTypes/user_typeView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getAbilities() {
      this.$store.dispatch('abilities/abilitiesList')
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('userTypes/user_typeSave', this.formData)
    },
  },
}
</script>
